.icon {
	--size: 16px;

	flex-shrink: 0;
	display: inline-block;
	width: var(--size);
	height: var(--size);
	background-image: var(--icon);
	background-size: var(--size);
}
